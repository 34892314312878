.CompanyNews1 {
  margin-bottom: 240px;
}

.CompanyNews1_cover {
  background-image: url('../../../public/image/companyNewsBgi.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%;
  width: 100%; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.CompanyNews1_cover-title {
  font-weight: 600;
  font-size: 72px;
  color: #FFFFFF;
  line-height: 100px;
  // margin-top: 50vh;
}

.CompanyNews1_content {
  margin: 0 120px 0 120px;
}

.CompanyNews1_content-title {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  margin-top: 120px;
  position: relative;
  z-index: 10;
}

// .CompanyNews1_content-title:after {
//   content: '';
//   display: block;
//   width: 193px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 8px;
//   z-index: -1;
// }

.CompanyNews1_content-title:nth-child(1) {
  margin-top: 200px;
}

.CompanyNews1_content-desc {
  font-weight: 400;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
  margin-top: 15px;
  padding-bottom: 10px;
  width: 620px;
}

.CompanyNews1_content-btn {
  width: 200px;
  height: 60px;
  // background: #03004D;
  border: 1px solid #03004D;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #03004D;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  // margin-right: 120px;
  text-decoration: none;
}

.CompanyNews1_content-btn:hover {
  background: #03004D;
  color: #FFFFFF;
}

.CompanyNews1_content-img {
  width: 1200px;
  height: 640px;
  // padding-bottom: 120px;
  margin-top: 70px;
  text-align: center;
  overflow: hidden;
  border-radius: 12px;
}

.CompanyNews1_content-img img {
  transition: transform 0.6s ease;
}

.CompanyNews1_content-img:hover img {
  transform: scale(1.1);
}

.CompanyNews1_contentNews {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  /* 确保padding和border不会影响整体宽度 */
  gap: 40px;
  /* 设置间距为40px */
}

.CompanyNews1_contentNews-item {
  width: 580px;
  height: 328px;
  border-radius: 12px;
  position: relative;
  // flex: 1;
  overflow: hidden;
}

.CompanyNews1_contentNews-itemBgc {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.06);
  border-radius: 12px;
  z-index: 1;
}

.CompanyNews1_contentNews-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* 使图片覆盖整个容器 */
  border-radius: 12px;
  transition: transform 0.6s ease;
  z-index: 2;
}

.CompanyNews1_contentNews-item:hover img {
  transform: scale(1.1);
}

.CompanyNews1_contentNews-itemDesc {
  font-weight: 500;
  font-size: 28px;
  color: #FFFFFF;
  line-height: 54px;
  position: absolute;
  bottom: 36px;
  left: 40px;
  right: 40px;
  z-index: 2;
}

.CompanyNews1_contentNews-itemDate {
  font-weight: 500;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 18px;
  position: absolute;
  bottom: 15px;
  right: 40px;
  z-index: 2;
}

.CompanyNews1_contentBottom {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  /* 确保子元素拉伸到相同的高度 */
  width: 100%;
  box-sizing: border-box;
  /* 确保padding和border不会影响整体宽度 */
  gap: 20px;
  /* 设置间距为20px */
}

.CompanyNews1_contentBottom a {
  text-decoration: none;
}

.CompanyNews1_contentBottomItem {
  flex: 1;
  background: #FFFFFF;
}

.CompanyNews1_bottomItemImg {
  width: 100%;
}

.CompanyNews1_bottomItemDesc {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin: 6px 34px 10px 10px;
}

@media only screen and (min-width: 2000px) {
  .CompanyNews1_content {
    margin-left: 10%;
    margin-right: 10%;
  }
}
