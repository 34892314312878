.Glaucoma {
  margin-bottom: 240px;
}

.Glaucoma_cover {
  background-image: url('../../../../public/image/glaucomaBgi.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%;
  width: 100%; */
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.Glaucoma_cover-title {
  font-weight: 600;
  font-size: 72px;
  color: #FFFFFF;
  line-height: 100px;
}

.Glaucoma_content {
  margin: 200px 120px 0 120px;
}

.Glaucoma_content-title {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  position: relative;
  z-index: 10;
}

// .Glaucoma_content-title:after {
//   content: '';
//   display: block;
//   width: 96px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 6px;
//   left: 190px;
//   z-index: -1;
// }

.Glaucoma_content-desc {
  font-weight: 400;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
  margin-top: 24px;
}

.Glaucoma_content-img {
  width: 1200px;
  height: 640px;
  margin-top: 70px;
  position: relative;
  text-align: center;
}

.Glaucoma_content-imgBgc {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(61, 52, 210, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  border-radius: 12px;
}

@media only screen and (min-width: 2000px) {
  .Glaucoma_content {
    margin-left: 10%;
    margin-right: 10%;
  }
}
