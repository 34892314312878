.Eyepal {
  margin-bottom: 240px;
}

.Eyepal_cover {
  background-image: url('../../../../public/image/eyepalBgi.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%;
  width: 100%; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.Eyepal_cover-title {
  font-weight: 600;
  font-size: 72px;
  color: #FFFFFF;
  line-height: 100px;
  // margin-top: 50vh;
}

.Eyepal_section1 {
  // height: 790px;
  margin: 200px 0 0 120px;
  // background-color: aliceblue;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.Eyepal_section1-bgi1 {
  width: 239px;
  height: 66px;
  position: absolute;
  left: 100px;
  bottom: 91px;
}

.Eyepal_section1-bgi2 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  right: -43px;
  bottom: 0;
}

.Eyepal_section1-img {
  // width: calc(100% - 120px);
  // height: 640px;
  width: 1200px;
  height: 640px;
  margin-right: 120px;
  margin-bottom: 150px;
  // background: rgba(61, 52, 210, 0.06);
  // box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  // border-radius: 12px;
  position: relative;
}

.Eyepal_section1-imgBgc {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(61, 52, 210, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  border-radius: 12px;
}

.Eyepal_section2 {
  // height: 402px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  // background-color: aliceblue;
  position: relative;
}

.Eyepal_section2-title {
  font-weight: 500;
  font-size: 52px;
  color: #000000;
  line-height: 78px;
  z-index: 10;
  position: relative;
}

// .Eyepal_section2-title:after {
//   content: '';
//   display: block;
//   width: 237px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 8px;
//   left: 40px;
//   z-index: -1;
// }

.Eyepal_section2-item {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 28px;
}

.Eyepal_section2-itemIcon {
  width: 24px;
  height: 24px;
  display: flex;
}

.Eyepal_section2-itemDesc {
  font-weight: 400;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
  margin-left: 20px;
}

.Eyepal_section3 {
  // height: 400px;
  margin: 70px 181px 0 102px;
  // background-color: aliceblue;
  position: relative;
}

.Eyepal_section3-bgi1 {
  width: 300px;
  height: 300px;
  background: #3D34D2;
  opacity: 0.04;
  border-radius: 50%;
  bottom: 18px;
  position: absolute;
}

.Eyepal_section3-bgi2 {
  width: 239px;
  height: 66px;
  right: 0;
  top: -1px;
  position: absolute;
}

.Eyepal_section3-content {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.Eyepal_section3-contentImg1 {
  // height: 580px;
  // width: calc((100% - 317px) / 2);
  width: 420px;
  height: 583px;
  margin-left: 138px;
  z-index: 10;
}

.Eyepal_section3-contentImg2 {
  // height: 580px;
  // width: calc((100% - 317px) / 2);
  width: 420px;
  height: 583px;
  margin-left: 120px;
  margin-right: 60px;
  z-index: 10;
}
