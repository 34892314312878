.Arta {
  margin-bottom: 240px;
}

.Arta_cover {
  background-image: url('../../../../public/image/artaBgi.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%;
  width: 100%; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.Arta_cover-title {
  font-weight: 600;
  font-size: 72px;
  color: #FFFFFF;
  line-height: 100px;
  // margin-top: 50vh;
}

.Arta_contentArta {
  // margin: 200px 139px 0 200px;
  margin-top: 140px;
  // margin: 0 10%;
  // margin-top: 167px;
  /* background-color: #e0dfdf; */
  // height: 658px;
  height: 724px;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
}

// .Arta_contentArta-top {
//   display: flex;
//   flex-flow: column;
//   justify-content: center;
//   align-items: center;
// }

.Arta_contentArta-title {
  font-weight: 500;
  font-size: 52px;
  color: #000000;
  line-height: 78px;
  z-index: 10;
  position: relative;
}

// .Arta_contentArta-title:after {
//   content: '';
//   display: block;
//   width: 85%;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   left: 50%;
//   bottom: 8px;
//   transform: translateX(-50%);
//   z-index: -1;
// }

.Arta_contentArta-subTitle {
  font-weight: 400;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
}

.Arta_contentArta-bottom {
  /* width: calc(100% - 35px); */
  // width: 100%;
  width: 1418px;
  margin-top: 72px;
  display: flex;
  flex-flow: column;
  position: relative;
}

.Arta_contentArta-bottomGroup {
  // width: calc(100% - 71px);
  margin: 0 200px 0 200px;
  display: flex;
  // justify-content: center;
  gap: 40px;
}

.Arta_contentArta-bottomItem {
  // width: calc((100% - 131px) / 3);
  width: 320px;
  height: 374px;
  // margin-right: 30px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.Arta_contentArta-bottomIcon {
  width: 120px;
  height: 120px;
}

.Arta_contentArta-bottomTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  margin-top: 35px;
}

.Arta_contentArta-bottomDesc {
  font-weight: 400;
  font-size: 18px;
  color: #999999;
  line-height: 28px;
  margin-top: 30px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Arta_contentArta-bottomBgi {
  width: 239px;
  height: 66px;
  position: absolute;
  top: -26px;
  right: 39px;
}

.Arta_contentArta-bottomBg2 {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 224px;
  bottom: 0;
  left: -30px;
  background: #3D34D2;
  opacity: 0.04;
  border-radius: 50%;
}

.Arta_contentCell {
  margin: 134px 120px 0 0;
  // margin-right: 5%;
  // margin-top: 70px;
  /* background-color: #e0dfdf; */
  width: calc(100% - 120px);
  // min-height: 658px;
  height: 752px;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.Arta_contentCell-left {
  // width: 55%;
  width: 701px;
  position: relative;
  max-width: 714px;
}

.Arta_contentCell-leftBgi1 {
  position: absolute;
  width: 239px;
  height: 66px;
  top: 9px;
  left: 110px;
}

.Arta_contentCell-leftBgi2 {
  position: absolute;
  width: 370px;
  height: 352px;
  background: #F0B000;
  border-radius: 12px;
  top: 130px;
  left: 120px;
}

.Arta_contentCell-leftBgi3 {
  width: 360px;
  height: 360px;
  position: absolute;
  bottom: 0;
  /* left: -10%; */
  left: -106px;
  bottom: 0;
  background: #3D34D2;
  opacity: 0.04;
  border-radius: 50%;
}

.Arta_contentCell-leftBgi4 {
  position: absolute;
  width: 238px;
  // max-width: 199px;
  // width: auto;
  height: 147px;
  // bottom: 13px;
  top: 498px;
  // right: 0;
  left: 463px;
}

.Arta_contentCell-leftImg {
  // max-width: 392px;
  // width: auto;
  width: 470px;
  height: 608px;
  margin-left: 170px;
  z-index: 10;
  position: absolute;
}

.Arta_contentCell-right {
  // width: 45%;
  width: 522px;
  margin-left: 97px;
}

.Arta_contentCell-rightTitle {
  // margin-left: 91px;
  margin-top: 36px;
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
}

// .Arta_contentCell-rightTitleTop {
//   position: relative;
//   z-index: 10;
// }

// .Arta_contentCell-rightTitleTop:after {
//   content: '';
//   display: block;
//   width: 170px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 8px;
//   z-index: -1;
// }

.Arta_contentCell-rightContent {
  width: 522px;
  height: 204px;
  // margin-left: 97px;
  margin-top: 44px;
  font-weight: 400;
  font-size: 20px;
  color: #999999;
  line-height: 34px;
}

.Arta_contentCell-rightImg {
  width: 411px;
  // max-width: 342px;
  // width: auto;
  height: 231px;
  // margin-left: 97px;
  margin-top: 7px;
}

.Arta_principles {
  margin-top: 19px;
  height: 791px;
  position: relative;
  overflow: hidden;
}

.Arta_principles-bgi1 {
  width: 360px;
  height: 360px;
  background: #3D34D2;
  opacity: 0.04;
  border-radius: 50%;
  position: absolute;
  right: -180px;
  top: 0;
}

.Arta_principles-content {
  height: 100%;
  position: relative;
  margin: 0 120px;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.Arta_principles-left {
  width: 548px;
  height: 100%;
  // margin-top: 236px;
}

.Arta_principles-leftTitle {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  margin-top: 236px;
}

.Arta_principles-leftDesc {
  font-weight: 500;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
  margin-top: 44px;
}

.Arta_principles-leftContent {
  font-weight: 400;
  font-size: 20px;
  color: #999999;
  line-height: 34px;
  margin-top: 20px;
}

.mt_20 {
  margin-top: 40px;
}

.Arta_principles-right {
  width: 528px;
  height: 100%;
  margin-left: 84px;
  position: relative;
}

.Arta_principles-rightBgi1 {
  width: 238px;
  height: 66px;
  position: absolute;
  left: 0;
  bottom: 58px;
}

.Arta_principles-rightBgi2 {
  width: 238px;
  height: 66px;
  position: absolute;
  right: 0;
  bottom: 119px;
}

.Arta_principles-rightImg {
  width: 400px;
  height: 450px;
  margin-left: 67px;
  margin-top: 341px;
  position: relative;
  z-index: 2;
}





// .Arta_principles-top {
//   display: flex;
//   flex-flow: column;
//   position: relative;
//   margin-left: 120px;
// }

// .Arta_principles-title {
//   font-weight: 500;
//   font-size: 48px;
//   color: #000000;
//   line-height: 68px;
//   // margin-left: 120px;
//   margin-top: 56px;
//   z-index: 10;
//   position: relative;
// }

// // .Arta_principles-title:after {
// //   content: '';
// //   display: block;
// //   width: 240px;
// //   height: 14px;
// //   background: #F0B000;
// //   opacity: 0.4;
// //   position: absolute;
// //   bottom: 8px;
// //   left: 61px;
// //   z-index: -1;
// // }

// .Arta_principles-subTitle {
//   font-weight: 500;
//   font-size: 24px;
//   color: #999999;
//   line-height: 40px;
//   margin-top: 34px;
//   // margin-left: 120px;
// }

// .Arta_principles-bgi {
//   width: 528px;
//   height: 450px;
//   position: absolute;
//   bottom: 0;
//   left: 50%;
//   transform: translateX(-50%);
// }

// .Arta_principles-img {
//   width: 400px;
//   height: 450px;
//   position: absolute;
//   left: 67px;
//   z-index: 2;
// }

// .Arta_principles-bgi3 {
//   width: 238px;
//   height: 66px;
//   position: absolute;
//   left: 0;
//   bottom: 58px;
// }

// .Arta_principles-bgi4 {
//   width: 238px;
//   height: 66px;
//   position: absolute;
//   right: 0;
//   bottom: 119px;
// }

// .Arta_principles-bgi2 {
//   width: 360px;
//   height: 360px;
//   background: #3D34D2;
//   opacity: 0.04;
//   border-radius: 50%;
//   position: absolute;
//   right: -180px;
//   top: 0;
// }

// .Arta_principles-contentBgi1 {
//   width: 360px;
//   height: 360px;
//   border-radius: 50%;
//   background: #3D34D2;
//   opacity: 0.04;
//   position: absolute;
//   top: 396px;
// }

// .Arta_principles-contentBgi2 {
//   width: 238px;
//   height: 147px;
//   bottom: 50px;
//   right: 182px;
//   position: absolute;
// }

// .Arta_principles-contentBgi3 {
//   width: 360px;
//   height: 360px;
//   border-radius: 50%;
//   background: #3D34D2;
//   opacity: 0.04;
//   position: absolute;
//   bottom: 0;
//   right: -180px;
// }

// .Arta_principles-contentBg4 {
//   width: 668px;
//   height: 460px;
//   // background: rgba(61, 52, 210, 0.08);
//   // border-radius: 12px;
//   position: absolute;
//   left: calc((100% - 668px) / 2);
//   bottom: 180px;
//   z-index: 10;
// }

// .Arta_principles-content {
//   width: calc(100% - 280px);
//   // height: 722px;
//   // position: absolute;
//   // position: relative;
//   margin: 140px 120px 0 160px;
//   display: flex;
//   justify-content: center;
// }

// .Arta_principles-contentItem {
//   font-weight: 400;
//   font-size: 20px;
//   color: #999999;
//   line-height: 34px;
//   z-index: 10;
// }

// .Arta_principles-contentItem:nth-child(2) {
//   margin-left: 384px;
// }

.Arta_clinical {
  margin: 146px 120px 0 0;
  /* background-color: #e0dfdf; */
  /* min-height: 580px; */
  // height: 696px;
  position: relative;
}

.Arta_clinical-top {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.Arta_clinical-topLeft {
  // width: 70%;
  /* background-color: #999999; */
  width: 807px;
  height: 505px;
  position: relative;
  // margin-left: 120px;
}

.Arta_clinical-Bgi1 {
  position: absolute;
  width: 239px;
  height: 66px;
  top: 0;
  left: 122px;
}

.Arta_clinical-Bgi2 {
  width: 360px;
  height: 360px;
  position: absolute;
  top: 145px;
  /* left: -10%; */
  left: -86px;
  background: #3D34D2;
  opacity: 0.04;
  border-radius: 50%;
}

.Arta_clinical-leftImg {
  /* position: absolute; */
  // width: 80%;
  width: 555px;
  height: 296px;
  margin-left: 120px;
  margin-top: 33px;
  display: flex;
  flex-flow: row;
  align-items: center;
  z-index: 10;
  position: relative;
}

.Arta_clinical-leftImgBgc {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(61, 52, 210, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  border-radius: 12px;
}

.Arta_clinical-leftLegend {
  // width: 20%;
  width: 150px;
  height: 160px;
  margin-left: -20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
}

.Arta_clinical-leftLegendBgc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F0B000;
  border-radius: 12px;
}

.Arta_clinical-legendItem {
  display: flex;
  flex-flow: row;
  margin: 6px 10px 0 30px;
}

.Arta_clinical-legendItemLeft {
  width: 12px;
  height: 12px;
  // background: #B0A2F6;
}

.Arta_clinical-legendItemRight {
  font-weight: 400;
  font-size: 14px;
  // color: #999999;
  line-height: 20px;
  margin-left: 10px;
}

.Arta_clinical-leftTip {
  font-weight: 400;
  font-size: 18px;
  color: #999999;
  line-height: 28px;
  margin-top: 30px;
  margin-left: 120px;
  display: flex;
  flex-flow: row;
}

.Arta_clinical-topRight {
  // width: 30%;
  /* background-color: #b5b3b3; */
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  margin-top: 113px;
  margin-left: 93px;
}

// .Arta_clinical-topRightBottom {
//   position: relative;
//   z-index: 10;
// }

// .Arta_clinical-topRightBottom:after {
//   content: '';
//   display: block;
//   width: 185px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 8px;
//   z-index: -1;
// }

.Arta_clinical-bottom {
  width: calc(100% - 120px);
  height: 123px;
  margin: 68px 0 0 120px;
  /* background-color: #fadada; */
  display: flex;
  flex-flow: row;
  justify-content: center;
  // justify-content: space-between;
  /* align-items: center; */
  // align-items: stretch;
  /* 确保子元素拉伸到相同的高度 */
  gap: 90px;
  /* 设置每个div之间的间隔 */
}

.Arta_clinical-bottomItem {
  display: flex;
  flex-flow: row;
  width: 340px;
  height: 100%;
  // flex: 1;
  /* 每个div宽度相等 */
}

.Arta_clinical-itemIcon {
  width: 80px;
  height: 80px;
  flex: 0 0 auto
}

.Arta_clinical-itemContent {
  margin-left: 30px;
  margin-top: 26px;
}

.Arta_clinical-itemTitle {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  line-height: 33px;
}

.Arta_clinical-itemDesc {
  font-weight: 400;
  font-size: 18px;
  color: #999999;
  line-height: 28px;
  margin-top: 8px;
}

.Arta_train {
  margin-top: 227px;
  /* background-color: #e0dfdf; */
  /* min-height: 580px; */
  position: relative;
  margin-left: 120px;
  overflow: hidden;
}

.Arta_train-content {
  margin-left: 120px;
  position: relative;
  // min-height: 700px;
  width: calc(100% - 120px);
  height: 702px;
  background: #CE9E81;
  border-radius: 50px 0px 0px 0px;
  filter: blur(0px);
  display: flex;
  flex-flow: row;
  /* justify-content: center; */
  /* justify-content: space-between; */
  align-items: center;
  // overflow: hidden;
}

.Arta_train-contentLeft img {
  width: 560px;
  height: 420px;
  margin-left: -120px;
  border-radius: 12px;
  position: relative;
  z-index: 2;
}

.Arta_train-contentRight {
  margin-left: 118px;
}

.Arta_train-rightTitle {
  font-weight: 500;
  font-size: 48px;
  color: #FFFFFF;
  line-height: 68px;
}

.Arta_train-rightBottom {
  margin-top: 40px;
  margin-right: 306px;
}

.Arta_train-rightItem {
  display: flex;
  flex-flow: row;
  // align-items: center;
}

.Arta_train-rightIcon {
  width: 16px;
  height: 11px;
  margin-top: 14px;
}

.Arta_train-rightDesc {
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 40px;
  margin-left: 18px;
}

.Arta_train-bgi1 {
  width: 460px;
  height: 460px;
  background: #FFFFFF;
  opacity: 0.04;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: -20px;
  top: -177px;
}

.Arta_train-bgi2 {
  width: 460px;
  height: 460px;
  background: #FFFFFF;
  opacity: 0.08;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: -70px;
  top: -227px;
}

.Arta_train-bgi3 {
  width: 660px;
  height: 660px;
  background: #FFFFFF;
  opacity: 0.04;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  top: 128px;
  right: -340px;
}

.Arta_train-bgi4 {
  width: 660px;
  height: 660px;
  background: #FFFFFF;
  opacity: 0.08;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  top: 178px;
  right: -381px;
}

@media only screen and (min-width: 2000px) {
  .Arta_principles-top {
    margin-left: 10%;
  }
}
