.Argos {
  margin-bottom: 240px;
}

.Argos_cover {
  background-image: url('../../../../public/image/argosBgi.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%;
  width: 100%; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.Argos_cover-title {
  font-weight: 600;
  font-size: 72px;
  color: #FFFFFF;
  line-height: 100px;
  // margin-top: 50vh;
}

.Argos_contentArgos {
  // margin: 200px 139px 0 200px;
  margin-top: 140px;
  // margin: 0 10%;
  // margin-top: 167px;
  /* background-color: #e0dfdf; */
  height: 724px;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
}

// .Argos_contentArgos-top {
//   display: flex;
//   flex-flow: column;
//   justify-content: center;
//   align-items: center;
// }

.Argos_contentArgos-title {
  font-weight: 500;
  font-size: 52px;
  color: #000000;
  line-height: 78px;
  z-index: 10;
  position: relative;
}

// .Argos_contentArgos-title:after {
//   content: '';
//   display: block;
//   width: 304px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   left: 50%;
//   bottom: 8px;
//   transform: translateX(-50%);
//   z-index: -1;
// }

.Argos_contentArgos-subTitle {
  font-weight: 400;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
}

.Argos_contentArgos-bottom {
  // width: 1091px;
  // height: 388px;
  width: 1418px;
  margin-top: 72px;
  display: flex;
  flex-flow: column;
  position: relative;
}

.Argos_contentArgos-bottomGroup {
  // width: calc(100% - 71px);
  margin: 0 200px 0 200px;
  display: flex;
  // justify-content: center;
  gap: 40px;
}

.Argos_contentArgos-bottomItem {
  // width: calc((100% - 131px) / 3);
  width: 320px;
  height: 374px;
  // margin-right: 30px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.Argos_contentArgos-bottomIcon {
  width: 120px;
  height: 120px;
}

.Argos_contentArgos-bottomTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  margin-top: 35px;
}

.Argos_contentArgos-bottomDesc {
  font-weight: 400;
  font-size: 18px;
  color: #999999;
  line-height: 28px;
  margin-top: 30px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Argos_contentArgos-bottomBgi {
  width: 239px;
  height: 66px;
  position: absolute;
  top: -26px;
  right: 39px;
}

.Argos_contentArgos-bottomBg2 {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 224px;
  bottom: 0;
  left: -30px;
  background: #3D34D2;
  opacity: 0.04;
  border-radius: 50%;
}

.Argos_portable {
  height: 582px;
  // margin-left: 120px;
  margin-top: 128px;
  // background-color: aliceblue;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  overflow: hidden;
}

.Argos_portable-left {
  // width: calc(50% - 30px);
  width: 538px;
  height: 100%;
  // margin-right: 30px;
  margin-left: 120px;
  // background-color: #e9e7e7;
  position: relative;
}

.Argos_portable-leftTitle {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  margin-top: 103px;
  position: relative;
  z-index: 10;
}

// .Argos_portable-leftTitle:after {
//   content: '';
//   display: block;
//   width: 243px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 8px;
//   left: 86px;
//   z-index: -1;
// }

.Argos_portable-leftItem {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 48px;
}

.mt_70 {
  margin-top: 70px;
}


.Argos_portable-leftIcon {
  width: 24px;
  height: 24px;
  display: flex;
  // margin-top: 59px;
}

.Argos_portable-item1Desc {
  width: 148px;
  height: 88px;
  border-radius: 44px;
  border: 1px solid #EFAF01;
  font-weight: 400;
  font-size: 20px;
  color: #999999;
  line-height: 28px;
  // margin-top: 27px;
  // margin-left: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ml_20 {
  margin-left: 20px;
}

.Argos_portable-leftArrow {
  width: 28px;
  height: 20px;
  // margin-top: 61px;
  margin-left: 20px;
}

.Argos_portable-item2Desc {
  font-weight: 400;
  font-size: 20px;
  color: #999999;
  line-height: 34px;
  // margin-top: 28px;
  // margin-left: 20px;
}

.Argos_portable-right {
  width: 675px;
  height: 100%;
  // background-color: #eae8e8;
  display: flex;
  // justify-content: center;
  position: relative;
  margin-left: 107px;
}

.Argos_portable-rightBgi1 {
  width: 239px;
  height: 66px;
  right: 143px;
  top: 37px;
  position: absolute;
}

.Argos_portable-rightBgi2 {
  width: 238px;
  height: 147px;
  bottom: 83px;
  left: 0;
  position: absolute;
}

.Argos_portable-rightBgi3 {
  width: 360px;
  height: 360px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  top: 182px;
  right: -63px;
}

.Argos_portable-rightBgi4 {
  width: 330px;
  height: 315px;
  background: #F0B000;
  border-radius: 12px;
  position: absolute;
  top: 134px;
  right: 120px;
}

.Argos_portable-rightImg {
  width: 480px;
  height: 582px;
  z-index: 10;
  margin-left: 24px;
}

.Argos_interest {
  height: 646px;
  // background-color: aliceblue;
  // margin-right: 78px;
  margin-top: 203px;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  overflow: hidden;
}

.Argos_interest-left {
  width: 728px;
  height: 100%;
  position: relative;
  // background-color: #d0cfcf;
}

.Argos_interest-leftBgi1 {
  width: 239px;
  height: 66px;
  left: 110px;
  top: 57px;
  position: absolute;
}

.Argos_interest-leftBgi2 {
  width: 238px;
  height: 147px;
  bottom: 10px;
  right: 0;
  position: absolute;
}

.Argos_interest-leftBgi3 {
  width: 360px;
  height: 360px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  bottom: 0;
  right: -146px;
}

.Argos_interest-leftBgi4 {
  width: 370px;
  height: 288px;
  background: #F0B000;
  border-radius: 12px;
  position: absolute;
  top: 150px;
  left: 120px;
}

.Argos_interest-leftImg {
  width: 480px;
  height: 582px;
  margin-left: 170px;
  position: relative;
}

.Argos_interest-right {
  width: 528px;
  height: 100%;
  margin-left: 66px;
  margin-right: 120px;
  // background-color: #d0cfcf;
}

.Argos_interest-rightTitle {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  margin-top: 120px;
  position: relative;
  z-index: 10;
}

.Argos_interest-rightContent {
  width: 435px;
  position: relative;
}

.Argos_interest-rightItem {
  font-weight: 400;
  font-size: 20px;
  color: #999999;
  line-height: 34px;
  margin-top: 50px;
}

// .Argos_interest-rightTitle:after {
//   content: '';
//   display: block;
//   width: 235px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 8px;
//   left: 104px;
//   z-index: -1;
// }

.Argos_science {
  height: 607px;
  // margin: 218px 120px 0 0;
  margin-top: 218px;
  // background-color: aliceblue;
  position: relative;
  // display: flex;
  // flex-flow: row;
  // justify-content: center;
}

.Argos_science-content {
  margin: 0 120px;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.Argos_science-left {
  width: 590px;
  height: 100%;
  // margin-left: 120px;
  // background-color: #e8e5e5;
}

.Argos_science-leftTitle {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  position: relative;
  z-index: 10;
}

// .Argos_science-leftTitle:after {
//   content: '';
//   display: block;
//   width: 243px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 8px;
//   left: 96px;
//   z-index: -1;
// }

.Argos_science-right {
  width: 528px;
  height: 100%;
  margin-left: 82px;
  position: relative;
  // background-color: #c8c6c6;
}

.Argos_science-rightBgi1 {
  width: 239px;
  height: 66px;
  position: absolute;
  top: 69px;
  right: 89px;
}

.Argos_science-rightBgi2 {
  width: 300px;
  height: 300px;
  background: #3D34D2;
  opacity: 0.04;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: -146px;
}

.Argos_science-rightBgi3 {
  width: 234px;
  height: 218px;
  background: #F0B000;
  border-radius: 12px;
  position: absolute;
  top: 89px;
  right: 0;
}

.Argos_science-rightImg {
  width: 478px;
  height: 312px;
  margin: 138px 50px 157px 0;
  position: relative;
}

.Argos_science-rightImgBgc {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(61, 52, 210, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.06);
  border-radius: 12px;
}

.Argos_bottom {
  margin-top: 208px;
  // background-color: #bebcbc;
  display: flex;
  justify-content: center;
}

.Argos_bottom-img {
  width: 1199px;
  height: 1100px;
}
