.CompanyProfile {
  /* width: 100%; */
  /* margin-top: 200px; */
}

.CompanyProfile_cover {
  background-image: url('../../../../public/image/aboutUs(1).png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%;
  width: 100%; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.CompanyProfile_cover-title {
  font-weight: 500;
  font-size: 72px;
  color: #FFFFFF;
  line-height: 100px;
}

.CompanyProfile_section1 {
  height: 388px;
  margin: 200px 120px 0 0;
  position: relative;
  // background-color: aliceblue;
}

.CompanyProfile_section1-bgi {
  width: 360px;
  height: 360px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  bottom: 0;
  left: -138px;
}

.CompanyProfile_section1-content {
  position: relative;
  display: flex;
  flex-flow: row;
  // justify-content: center;
  margin-left: 120px;
}

.CompanyProfile_section1-contentLeft {
  width: 562px;
  height: 68px;
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  position: relative;
  z-index: 10;
}

// .CompanyProfile_section1-contentLeft:after {
//   content: '';
//   display: block;
//   width: 190px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 6px;
//   z-index: -1;
// }

.CompanyProfile_section1-contentRight {
  width: 600px;
  height: 238px;
  font-weight: 400;
  font-size: 20px;
  color: #999999;
  line-height: 34px;
  margin-left: 38px;
}

.CompanyProfile_section2 {
  margin: 180px 210px 0 210px;
  // background-color: aliceblue;
  height: 657px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 30px;
}

.CompanyProfile_section2-item {
  width: 320px;
  height: 657px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  border-radius: 12px;
}

.CompanyProfile_section2-itemImg {
  width: 120px;
  height: 120px;
  background: #F7F7FD;
  border-radius: 8px;
  margin-top: 40px;
  margin-left: 40px;
}

.CompanyProfile_section2-itemTitle {
  font-weight: 500;
  font-size: 30px;
  color: #000000;
  line-height: 36px;
  margin-top: 24px;
  margin-left: 36px;
}

.CompanyProfile_section2-itemLine {
  width: 248px;
  height: 1px;
  background-color: #000000;
  opacity: 0.08;
  margin: 25px 36px 0 36px;
}

.CompanyProfile_section2-itemTeam {
  display: flex;
  flex-flow: column;
  margin-top: 7px;
  margin-left: 36px;
}

.CompanyProfile_section2-team {
  display: flex;
  flex-flow: row;
  margin-top: 26px;
}

.CompanyProfile_section2-teamIcon {
  width: 24px;
  height: 24px;
}

.CompanyProfile_section2-teamDesc {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  line-height: 22px;
  margin-left: 12px;
}

.CompanyProfile_section2-itemDesc {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 32px;
  margin: 25px 36px 0 36px;
}

.CompanyProfile_section3 {
  // height: 2434px;
  // background-color: aliceblue;
  margin-top: 180px;
  // margin-bottom: 240px;
  position: relative;
  overflow: hidden;
}

.CompanyProfile_section3-bgi1 {
  width: 360px;
  height: 360px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  right: -260px;
  top: 28px;
}

.CompanyProfile_section3-bgi2 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  left: -30px;
  top: 963px;
}

.CompanyProfile_section3-bgi3 {
  width: 420px;
  height: 420px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  right: -60px;
  bottom: 160px;
}

.CompanyProfile_section3-title {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  margin-left: 120px;
  position: relative;
  z-index: 10;
}

// .CompanyProfile_section3-title:after {
//   content: '';
//   display: block;
//   width: 190px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 6px;
//   z-index: -1;
// }

.CompanyProfile_section3-content {
  height: 2200px;
  margin: 60px 207px 0 207px;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.CompanyProfile_section3-contentLine1 {
  width: 1px;
  height: 1900px;
  background: #E6E6E6;
}

.CompanyProfile_section3-contentLeft {
  width: 513px;
  // height: 100%;
  // background-color: #f4f2f2;
  position: relative;
  margin-top: 70px;
}

.CompanyProfile_section3-contentRight {
  width: 512px;
  // height: 100%;
  // background-color: #dfdede;
  margin-top: 280px;
}

.CompanyProfile_section3-contentItem {
  width: 100%;
  min-height: 179px;
  // background-color: #e8f8d3;
  border-top: 1px solid #E6E6E6;
  position: relative;
  // margin-top: 319px;
}

.CompanyProfile_section3-contentLeftItem {
  width: 100%;
  min-height: 179px;
  // background-color: #e8f8d3;
  border-top: 1px solid #E6E6E6;
  position: relative;
}

.CompanyProfile_section3-contentLeftItem:not(:first-of-type) {
  margin-top: 319px;
}

.CompanyProfile_section3-itemDate {
  font-weight: 500;
  font-size: 30px;
  color: #000000;
  line-height: 36px;
  margin-top: 33px;
  margin-right: 20px;
  text-align: right;
}

.CompanyProfile_section3-itemLine {
  width: 80px;
  height: 1px;
  background: #000000;
  opacity: 0.12;
  float: right;
  margin-top: 10px;
  margin-right: -10px;
}

.CompanyProfile_section3-itemCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3D34D2;
  float: right;
  margin-right: -10px;
  position: relative;
  z-index: 10;
}

.CompanyProfile_section3-itemDesc {
  width: 411px;
  font-weight: 400;
  font-size: 20px;
  color: #999999;
  line-height: 34px;
  margin-top: 22px;
}

.CompanyProfile_section3-contentRightItem {
  width: 100%;
  min-height: 179px;
  // background-color: #e8f8d3;
  border-top: 1px solid #E6E6E6;
  position: relative;
}

.CompanyProfile_section3-contentRightItem:not(:first-of-type) {
  margin-top: 387px;
}

.CompanyProfile_section3-RightItemDate {
  font-weight: 500;
  font-size: 30px;
  color: #000000;
  line-height: 36px;
  margin-top: 33px;
  margin-left: 20px;
}

.CompanyProfile_section3-RightItemLine {
  width: 80px;
  height: 1px;
  background: #000000;
  opacity: 0.12;
  margin-top: -10px;
}

.CompanyProfile_section3-RightItemCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3D34D2;
  position: relative;
  z-index: 10;
  margin-left: -10px;
}

.CompanyProfile_section3-RightItemDesc {
  width: 411px;
  font-weight: 400;
  font-size: 20px;
  color: #999999;
  line-height: 34px;
  margin-top: 22px;
  margin-left: 110px;
}


@media only screen and (min-width: 2000px) {
  .CompanyProfile_section1 {
    margin-right: 10%;
  }

  .CompanyProfile_section1-content {
    margin-left: 10%;
  }

  .CompanyProfile_section3-title {
    margin-left: 10%;
  }
}
