.Header {
  width: 100%;
  height: 92px;
  position: relative;
  margin-bottom: -92px;
  top: 0;
  z-index: 10;
  /* background-color: #3D34D2; */
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.Header_logo {
  /* width: 310px; */
  /* width: 100%; */
  max-width: 229px;
  min-width: 100px;
  /* height: 22px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 130px;
}

.Header_menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 182px;
}

.Header_menu li {
  font-size: 18px;
  font-weight: 400;
  height: 92px;
  position: relative;
  display: flex;
  align-items: center;
  // border-bottom: 1px solid transparent;
  padding: 0 10px;
}

.Header_menu li:hover .Header_menu-title{
  border-radius: 22px;
  display: flex;
  align-items: center;
  background: #03004D;
  color: #FFFFFF;
}

.Header_menu li:hover:nth-child(1),
.Header_menu li:hover:nth-child(3) {
  // border-bottom: 1px solid transparent;
}

.Header_menu-title {
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
}

.Header_menu-titleColor {
  color: #03004D;
  text-decoration: none;
  padding: 10px 20px;
}

.Header_menu-titleSel {
  border-radius: 22px;
  display: flex;
  align-items: center;
  background: #03004D;
}

.FCSTColor {
  color: #fff;
}

.Header_menu-item {
  display: none;
  position: absolute;
  left: 50%;
  // top: calc(100% + 4px);
  top: 100%;
  background: #FFFFFF;
  border-radius: 12px;
  transform: translateX(-50%);
}

.Header_menu li:hover .Header_menu-item {
  display: block;
}

.Header_menu-item a {
  text-decoration: none;
}

.Header_menu-itemSolution {
  width: 388px;
}

.Header_menu-itemFcst {
  width: 170px;
}

.Header_menu-itemAboutUs {
  width: 170px;
}

.Header_menu-itemLink {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  margin-top: 30px;
  margin-left: 48px;
}

.Header_menu-itemLink:last-child {
  margin-bottom: 30px;
}

.Header_menu-itemLink:hover {
  color: #03004D;
}
