.App {
  background-color: #F0F2F5;
}

/* 滚动条 */
/* ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, 0.3);
  border-radius: 1em;
}

::-webkit-scrollbar-track {
  background-color: rgba(50, 50, 50, 0.1);
  border-radius: 1em;
} */