.SolutionIndex {
  margin-bottom: 240px;
}

.SolutionIndex_cover {
  background-image: url('../../../../public/image/FCST(7).png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // background-size: 100% 100%;
  // width: 100%; 
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.SolutionIndex_cover-title {
  font-size: 60px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 60px;
  letter-spacing: 1px;
}

.SolutionIndex_content {
  // height: 4000px;
  // margin-top: 80px;
  // padding-bottom: 180px;
  // background-color: aliceblue;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  // align-items: center;
}

.SolutionIndex_content a {
  text-decoration: none;
}

.SolutionIndex_content-bgi1 {
  width: 360px;
  height: 360px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  top: 378px;
  left: -112px;
}

.SolutionIndex_content-bgi2 {
  width: 238px;
  height: 147px;
  position: absolute;
  top: 305px;
  right: 0;
}

.SolutionIndex_content-bgi3 {
  width: 238px;
  height: 147px;
  position: absolute;
  top: 1601px;
  left: 60px;
}

.SolutionIndex_content-bgi4 {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  top: 1575px;
  right: -171px;
}

.SolutionIndex_content-bgi5 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  top: 2100px;
  left: -52px;
}

// .SolutionIndex_content-bgi6 {
//   width: 238px;
//   height: 147px;
//   position: absolute;
//   top: 3141px;
//   left: 404px;
// }

.SolutionIndex_content-bgi7 {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  top: 2850px;
  right: -80px;
}

.SolutionIndex_content-top {
  margin: 183px 120px 0 120px;
}

.SolutionIndex_content-title {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
}

.SolutionIndex_content-desc {
  font-weight: 400;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
  margin-top: 15px;
}

.SolutionIndex_content-item {
  margin: 183px 120px 0 120px;
  z-index: 10;
  display: flex;
  justify-content: center;

  &.first-item {
    margin-top: 70px; // 设置第一个项的上边距 
  }
}

.SolutionIndex_content-itemImg {
  width: 1200px;
  height: 620px;
  position: relative;
}

.SolutionIndex_content-itemContent {
  width: 100%;
  position: absolute;
  margin-top: 70px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.SolutionIndex_content-itemTitle {
  font-weight: 500;
  font-size: 32px;
  color: #000000;
  line-height: 45px;
}

.SolutionIndex_content-itemSubTitle {
  font-weight: 400;
  font-size: 22px;
  color: #999999;
  line-height: 30px;
  margin-top: 10px;
}

.SolutionIndex_content-itemImg1 {
  width: 1200px;
  height: 620px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SolutionIndex_content-itemTitle1 {
  font-weight: 500;
  font-size: 32px;
  color: #FFFFFF;
  line-height: 45px;
  position: absolute;
  text-align: center;
}
