.AllNews {
  // position: relative;
  // height: 100vh;
  margin-bottom: 240px;
}

.AllNews_bgc1 {
  width: 594px;
  height: 594px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.02;
  position: absolute;
  left: -297px;
  top: -331px;
}

.AllNews_bgc2 {
  width: 494px;
  height: 494px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  left: -247px;
  top: -281px;
}

.AllNews_bgc3 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  right: 310px;
  top: 173px;
}

.AllNews_bgc4 {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  right: 0;
  top: 331px;
}

.AllNews_bgc5 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  left: 27px;
  top: 505px;
}

.AllNews_content {
  // width: calc(100% - 240px);
  // height: auto;
  // height: 300px;
  // margin: 70px 120px 0 120px;
  margin: 283px 120px 0 120px;
  // background-color: aliceblue;
  // position: relative;
}

.AllNews_content a {
  text-decoration: none;
}

.AllNews_title {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  z-index: 10;
  position: absolute;
  top: 145px;
  left: 122px;
}

// .AllNews_title:after {
//   content: '';
//   display: block;
//   width: 192px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 8px;
//   z-index: -1;
// }

.AllNews_contentItem {
  width: 100%;
  // min-height: 164px;
  height: 164px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  border-radius: 12px;
  margin-bottom: 40px;
  display: flex;
  flex-flow: row;
  align-items: center;
  position: relative;
}

.AllNews_contentItem-img {
  // width: 30%;
  // height: 100%;
  width: 325px;
  height: 164px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.AllNews_contentItem-right {
  // width: calc(70% - 30px);
  width: calc(100% - 355px);
  height: 100%;
  position: relative;
  margin: 24px 10px 17px 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.AllNews_contentItem-rightTitle {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  line-height: 24px;
}

.AllNews_contentItem-rightDate {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 18px;
  margin-top: 10px;
}

.AllNews_contentItem-rightDesc {
  font-weight: 400;
  font-size: 18px;
  color: #999999;
  line-height: 32px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 限制在一个块元素显示的文本的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* 溢出用省略号表示 */
}

@media only screen and (min-width: 2000px) {
  .AllNews_title {
    left: 10%;
  }

  .AllNews_content {
    margin-left: 10%;
    margin-right: 10%;
  }
}
