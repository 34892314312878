.App_content {
  width: 100%;
  overflow: hidden;
  margin-bottom: 240px;
}

.App_cover {
  background-image: url('../../../public/image/indexBgi.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // background-size: 100% 100%;
  // width: 100%; 
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.App_cover-title {
  font-size: 60px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 83px;
  // margin-top: 30vh;
}

/* 以下为解决方案UI重构样式 */
.Solution {
  width: calc(100% - 120px);
  height: 794px;
  margin-left: 120px;
  margin-top: 196px;
  // background-color: #E7E7E7;
  position: relative;
}

.Solution_title {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  position: relative;
  opacity: 0;
  margin-top: 27px;
}

.Solution1--ani .Solution_title {
  animation: translateYLine 1s forwards;
}

// .Solution_title:after {
//   content: '';
//   display: block;
//   width: 96px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   right: 0;
//   bottom: 8px;
//   z-index: -1;
// }

.Solution_desc {
  font-weight: 400;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
  margin-top: 24px;
  width: 743px;
  height: 80px;
  opacity: 0;
}

.Solution1--ani .Solution_desc {
  animation: translateYLine 1s forwards 1s;
}

.Solution_btn {
  width: 200px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #03004D;
  font-weight: 500;
  font-size: 18px;
  color: #03004D;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  margin-right: 120px;
  // cursor: pointer;
  opacity: 0;
}

.Solution1--ani .Solution_btn {
  animation: translateYLine 1s forwards 2s;
}

.Solution_btn:hover {
  background: #03004D;
  color: #FFFFFF;
}

.Solution a {
  text-decoration: none;
}

.Solution_content {
  position: relative;
  margin-top: 45px;
  margin-right: 120px;
  opacity: 0;
  display: flex;
  justify-content: center;
}

.Solution1--ani .Solution_content {
  animation: translateYLine 1s forwards 2.5s;
}

.Solution_content a {
  text-decoration: none;
}

.Solution_bgc1 {
  position: absolute;
  width: 239px;
  height: 66px;
  top: 0;
  right: 0;
}

.Solution_bgc2 {
  position: absolute;
  width: 238px;
  height: 66px;
  // top: 389px;
  // left: 270px;
  bottom: -73px;
  left: 217px;
}

.Solution_bgc3 {
  position: absolute;
  width: 360px;
  height: 360px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  top: 190px;
  right: -196px;
  // right: 0;
}

.Solution_contentBottom {
  display: flex;
  flex-flow: row;
  z-index: 2;
  gap: 30px;
}

.Solution_contentItem {
  position: relative;
  // width: calc((100% - 180px) / 3);
  // height: 430px;
  width: 380px;
  height: 430px;
  // background-color: #999999;
  // margin-right: 30px;
  border-radius: 12px;
}

.Solution_contentItem-bgc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(61, 52, 210, 0) 0%, rgba(61, 52, 210, 0.4) 100%);
  border-radius: 12px;
  z-index: 1;
  opacity: 0.5;
}

.Solution_ContentImg {
  width: 100%;
  // height: 234px;
  position: absolute;
  overflow: hidden;
  // position: relative;
  z-index: 2;
}

.Solution_ContentImg img {
  width: 100%;
  height: auto;
  transition: transform 0.6s ease;
  /* 平滑过渡效果 */
}

.Solution_contentItem:hover img {
  transform: scale(1.2);
}

.Solution_contentItem:hover .Solution_contentItem-bgc {
  opacity: 1;
}

.Solution_contentEyepal {
  position: absolute;
  width: 80%;
  margin: 0 10%;
  margin-top: 140px;
}

.Solution_itemTitle {
  font-weight: 500;
  font-size: 30px;
  color: rgba(3, 0, 77, 0.7);
  line-height: 32px;
  margin: 308px 0 0 32px;
  position: relative;
}

.Solution_itemDesc {
  font-weight: 400;
  font-size: 18px;
  color: rgba(3, 0, 77, 0.7);
  line-height: 32px;
  margin: 8px 0 0 32px;
}

/* 以下为解决方案重构样式 */
.Solution1 {
  width: 100%;
  /* height: 100vh; */
  /* margin-left: 10%; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  align-items: center;
  overflow: auto;
}

.Solution1_left {
  /* display: flex;
  justify-content: center; */
  width: 20%;
  font-family: 'STSongti-SC-Black';
}

@keyframes translateYLine {
  0% {
    transform: translateY(100%);
    opacity: 0;
    /* 初始状态不可见 */
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.Solution1_left-title {
  font-size: 30px;
  line-height: 30px;
  color: #333333;
  font-weight: 900;
  opacity: 0;
}

.Solution1--ani .Solution1_left-title {
  animation: translateYLine 1s forwards 1s;
}

.Solution1_left-content {
  font-size: 22px;
  line-height: 30px;
  margin-top: 40px;
  opacity: 0;
}

.Solution1--ani .Solution1_left-content {
  animation: translateYLine 1s forwards 2s;
}

.Solution1_left-btn {
  height: 30px;
  width: 120px;
  margin-top: 40px;
  cursor: pointer;
  color: #9F1212;
  border: 1px solid #9F1212;
  opacity: 0;
}

.Solution1--ani .Solution1_left-btn {
  animation: translateYLine 1s forwards 2.8s;
}

.Solution1_left-btn:hover {
  color: #FFFFFF;
  background-color: #9F1212;
}

.Solution1_right {
  width: calc(80% - 160px);
  margin-left: 80px;
  overflow: hidden;
  position: relative;
}

.Solution1_right-inner {
  display: flex;
  transition: transform 1s ease;
}

.Solution1_right-title {
  font-size: 24px;
  color: #333333;
  font-weight: 600;
}

.Solution1_right-arta {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  opacity: 0;
}

.Solution1--ani .Solution1_right-arta {
  animation: translateXLine 1s forwards 3s;
}

@keyframes translateXLine {
  0% {
    transform: translateX(100%);
    opacity: 0;
    /* 初始状态不可见 */
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.Solution1_right-artaLogo {
  /* width: 200px; */
  height: 32px;
}

.Solution1_right-artaImg {
  width: 70%;
  height: 300px;
  margin-left: 30px;
}

.Solution1_right-agros {
  width: calc(50% - 5px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  opacity: 0;
}

.Solution1--ani .Solution1_right-agros {
  animation: translateYLine 1s forwards 4s;
}

.Solution1_right-agrosLogo {
  width: 200px;
  /* height: 32px; */
}

.Solution1_right-agrosImg {
  /* width: calc(60% - 10px); */
  width: calc(100% - 190px);
  height: 300px;
  margin-left: 10px;
  flex: 1;
}

.Solution1_right-eyepal {
  width: calc(50% - 5px);
  /* margin-left: 10px; */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  opacity: 0;
}

.Solution1--ani .Solution1_right-eyepal {
  animation: translateXLine 1s forwards 5s;
}

.Solution1_right-eyepalLogo {
  width: 100%;
  /* height: 32px; */
}

.Solution1_right-eyepalImg {
  /* width: calc(60% - 10px); */
  width: calc(100% - 190px);
  height: 300px;
  margin-left: 10px;
  flex: 1;
}

.Solution1_right-glaucoma {
  /* min-width: 800px; */
  margin-left: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.Solution1_right-glaucomaImg {
  width: 300px;
  height: 300px;
  margin-left: 20px;
}

/* .Solution1_arrow {
  margin-left: 20px;
} */

/* 以下为UI重构的公司动态样式 */
.CompanyNews {
  // width: 100%;
  height: 866px;
  margin-top: 68px;
  // margin-left: 42px;
  position: relative;
}

.CompanyNews_title {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  margin-left: 120px;
  position: relative;
  z-index: 10;
  opacity: 0;
}

.Solution1--ani .CompanyNews_title {
  animation: translateYLine 1s forwards 0.5s;
}

// .CompanyNews_title:after {
//   content: '';
//   display: block;
//   width: 193px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 8px;
//   z-index: -1;
// }

.CompanyNews_btn {
  width: 200px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #03004D;
  font-weight: 500;
  font-size: 18px;
  color: #03004D;
  line-height: 40px;
  // margin-top: 16px;
  margin-right: 120px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  text-decoration: none;
  position: relative;
}

.Solution1--ani .CompanyNews_btn {
  animation: translateYLine 0.5s forwards 1s;
}

.CompanyNews_btn:hover {
  background: #03004D;
  color: #FFFFFF;
}

.CompanyNews_content {
  // width: calc(100% - 198px);
  // height: 512px;
  // margin: 146px 120px 0 78px;
  // width: 100%;
  // margin: 46px 120px 0 0;
  // background-color: #b7b5b5;
  width: calc(100% - 240px);
  margin: 0 120px;
  margin-top: 21px;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.CompanyNews_content-left {
  // width: 55%;
  // height: 100%;
  // width: 646px;
  // height: 512px;
  width: 686px;
  height: 777px;
  margin-top: -50px;
  // box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.06);
  // border-radius: 12px;
  position: relative;
  // overflow: hidden;
  opacity: 0;
}

.Solution1--ani .CompanyNews_content-left {
  animation: translateYLine 1s forwards 1.5s;
}

.CompanyNews_bgi1 {
  width: 160px;
  height: 160px;
  background: #3D34D2;
  opacity: 0.04;
  border-radius: 50%;
  bottom: 0;
  left: -120px;
  position: absolute;
}

.CompanyNews_bgi3 {
  width: 238px;
  height: 66px;
  position: absolute;
  top: 25px;
  right: -40px;
}

// .CompanyNews_content-left11 {
//   width: 646px;
//   height: 512px;
//   position: relative;
//   overflow: hidden;
// }

.CompanyNews_content-leftContent {
  // width: calc(100% - 120px);
  // height: 512px;
  width: 646px;
  height: 646px;
  margin-top: 49px;
  position: relative;
  // background-color: aliceblue;
  // top: 100px;
  // left: 120px;
  overflow: hidden;
  border-radius: 12px;
}

.CompanyNews_content-leftImg {
  width: 646px;
  height: 646px;
  position: relative;
}

.CompanyNews_content-leftImg img {
  transition: transform 0.6s ease;
}

.CompanyNews_content-leftContent:hover img {
  transform: scale(1.1);
}

.CompanyNews_content-leftBtn {
  width: 133px;
  height: 29px;
  background: #FFFFFF;
  border-radius: 17px;
  font-weight: 500;
  font-size: 14px;
  color: #03004D;
  line-height: 20px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 34px;
  left: 44px;
}

.CompanyNews_content-leftTitle {
  font-weight: 500;
  font-size: 36px;
  color: #FFFFFF;
  line-height: 54px;
  position: absolute;
  bottom: 72px;
  left: 45px;
  right: 44px;
}

.CompanyNews_content-leftDate {
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  position: absolute;
  bottom: 20px;
  right: 27px;
}

.CompanyNews_content-right {
  // width: calc(45% - 80px);
  width: 474px;
  // height: 578px;
  // width: 594px;
  // height: 603px;
  position: relative;
  margin-left: 80px;
  // margin-top: 100px;
  // margin-top: 55px;
  // display: flex;
  // flex-flow: column;
  // overflow: hidden;
  opacity: 0;
}

.Solution1--ani .CompanyNews_content-right {
  animation: translateYLine 1s forwards 2.5s;
}

.CompanyNews_bgi2 {
  width: 187px;
  height: 66px;
  position: absolute;
  bottom: 14px;
  right: -120px;
}

.CompanyNews_content-rightContent {
  width: 474px;
  height: 560px;
  margin-top: 45px;
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.CompanyNews_content-rightInner {
  // width: 474px;
  // position: relative;
  // z-index: 10;
  // background-color: aliceblue;
}

.CompanyNews_content-rightInner a {
  text-decoration: none;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  line-height: 40px;
  display: flex;
  flex-flow: column;
}

.CompanyNews_content-rightImg {
  // width: 100%;
  // height: 190px;
  width: 474px;
  height: 190px;
  border-radius: 10px;
  margin-top: 50px;
}

.CompanyNews_content-rightBottom a {
  text-decoration: none;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  line-height: 40px;
  display: flex;
  flex-flow: column;
}

.CompanyNews_content-rightBottom a:hover {
  color: #03004D;
}

.CompanyNews_content-rightItem {
  font-weight: 400;
  font-size: 24px;
  color: #03004D;
  line-height: 40px;
  margin-top: 32px;
  width: 474px;
}

// .CompanyNews_content-rightItem:last-child {
//   padding-bottom: 100px;
// }


.FCST {
  width: 80%;
  margin-left: 10%;
  /* background: #dfdfdf; */
  margin-top: 200px;
  display: flex;
  flex-flow: row nowrap;
}

.FCST_left {
  width: 40%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.Rectangle_grey {
  margin-top: 116px;
  margin-left: 20px;
  position: absolute;
}

.Rectangle_red {
  position: absolute;
  margin-top: 302px;
}

.big_image {
  position: absolute;
  margin-left: 40px;
}

.greenIcon {
  position: absolute;
  margin-left: -24px;
  margin-top: -6px;
}

.FCST_right {
  width: 60%;
  margin-left: 40px;
}

.FCST_right-top {
  font-size: 30px;
  font-family: 'STSongti-SC';
  font-weight: 900;
  color: #333333;
  line-height: 30px;
  letter-spacing: 1px;
  margin-left: 40px;
  margin-top: 20px;
}

.FCST_banner {
  width: 90%;
  overflow: hidden;
  position: relative;
  height: 264px;
}

.FCST_bannerContent {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  /* transform: translateX(-100%); */
}

.FCST_bannerContent--trs {
  transition: transform ease-in-out .3s;
}

.FCST_right-item {
  flex: 1 0 100%;
  width: 100%;
  height: 224px;
  background: #FFFFFF;
  box-shadow: 0px 24px 48px 0px rgba(0, 17, 86, 0.08);
  border-radius: 8px;
}

.clearfix:after {
  content: '\20';
  display: block;
  height: 0;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.FCST_mark {
  margin-top: -20px;
  margin-left: 85%;
}

.FCST_rightItem-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 16px;
  margin-left: 40px;
}

.FCST_rightItem-date {
  font-size: 12px;
  color: #999999;
  line-height: 12px;
  margin: 8px 40px 0 40px;
}

.FCST_rightItem-desc {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  margin: 18px 40px 0 40px;
  padding-bottom: 20px;
  height: 120px;
  /* overflow-y: auto; */
  width: calc(100% - 80px);
  display: -webkit-box;
  /* 将对象作为弹性伸缩盒子模型显示 */
  -webkit-box-orient: vertical;
  /* 设置伸缩盒子的子元素排列方式 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 文本溢出显示省略号 */
  -webkit-line-clamp: 5;
}

.FCST_right-bottom {
  margin-top: 40px;
}

/* 以下为UI重构的关于我们样式 */
.AboutUs {
  margin-top: 178px;
  /* background-color: #e0dfdf; */
  /* min-height: 580px; */
  position: relative;
  margin-left: 120px;
}

.AboutUs_content {
  margin-left: 120px;
  position: relative;
  // min-height: 700px;
  width: calc(100% - 120px);
  height: 800px;
  background: #3D34D2;
  border-radius: 50px 0px 0px 0px;
  filter: blur(0px);
  display: flex;
  flex-flow: row;
  align-items: center;
}

.AboutUs_contentLeft {
  width: 460px;
  height: 600px;
  margin-left: -120px;
  // position: relative;
  /* opacity: 0; */
}

/* .Solution1--ani .AboutUs_contentLeft {
  animation: translateXNLine 0.5s forwards 1s;
}

@keyframes translateXNLine {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
} */

.AboutUs_contentRight {
  margin-left: 158px;
}

.AboutUs_rightTitle {
  font-weight: 500;
  font-size: 48px;
  color: #FFFFFF;
  line-height: 68px;
  opacity: 0;
}

.Solution1--ani .AboutUs_rightTitle {
  animation: translateYLine 1s forwards 0.5s;
}

.AboutUs_rightDesc {
  width: 430px;
  height: 120px;
  margin-top: 92px;
  margin-right: 180px;
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 40px;
  opacity: 0;
}

.Solution1--ani .AboutUs_rightDesc {
  animation: translateYLine 1s forwards 1.5s;
}

.AboutUs_btn {
  width: 200px;
  height: 60px;
  // background: #03004D;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 40px;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  text-decoration: none;
}

.Solution1--ani .AboutUs_btn {
  animation: translateYLine 0.5s forwards 2s;
}

.AboutUs_btn:hover {
  // background: #2a2572;
  // color: #FFFFFF;
  background-color: #FFFFFF;
  color: #2a2572;
}

.AboutUs_bgi1 {
  width: 460px;
  height: 460px;
  background: #FFFFFF;
  opacity: 0.04;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 100px;
  top: -128px;
}

.AboutUs_bgi2 {
  width: 460px;
  height: 460px;
  background: #FFFFFF;
  opacity: 0.08;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 50px;
  top: -178px;
}

.AboutUs_bgi3 {
  width: 660px;
  height: 660px;
  background: #FFFFFF;
  opacity: 0.04;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  top: 148px;
  right: -340px;
}

.AboutUs_bgi4 {
  width: 660px;
  height: 660px;
  background: #FFFFFF;
  opacity: 0.08;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  top: 178px;
  right: -381px;
}


// @media only screen and (min-width: 2500px) {
//   .Solution {
//     width: 90%;
//     margin-left: 10%;
//   }

//   .Solution_btn {
//     margin-right: 10%;
//   }

//   .Solution_content {
//     margin-right: 10%;
//   }

//   .CompanyNews_title {
//     margin-left: 10%;
//   }

//   .CompanyNews_btn {
//     margin-right: 10%;
//   }

//   .AboutUs {
//     margin-left: 10%;
//   }

//   .AboutUs_contentLeft {
//     margin-left: -10%;
//   }
// }
