.SciencePopularization {
  // position: relative;
  // height: 100vh;
  margin-bottom: 240px;
}

.SciencePopularization_bgc1 {
  width: 594px;
  height: 594px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.02;
  position: absolute;
  left: -297px;
  top: -331px;
}

.SciencePopularization_bgc2 {
  width: 494px;
  height: 494px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  left: -247px;
  top: -281px;
}

.SciencePopularization_bgc3 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  right: 310px;
  top: 173px;
}

.SciencePopularization_bgc4 {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  right: 0;
  top: 331px;
}

.SciencePopularization_bgc5 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #3D34D2;
  opacity: 0.04;
  position: absolute;
  left: 27px;
  top: 505px;
}

.SciencePopularization_top {
  position: absolute;
  top: 145px;
  left: 120px;
  z-index: 10;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.SciencePopularization_top-img {
  width: 40px;
  height: 36px;
  cursor: pointer;
}

.SciencePopularization_title {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  margin-left: 20px;
}

// .SciencePopularization_title:after {
//   content: '';
//   display: block;
//   width: 192px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 6px;
//   z-index: -1;
// }

.SciencePopularization_content {
  // width: calc(100% - 240px);
  // height: 300px;
  margin: 283px 120px 0 120px;
  // background-color: aliceblue;
  position: relative;
}

.SciencePopularization_content a {
  text-decoration: none;
}

.SciencePopularization_contentItem {
  width: 100%;
  // min-height: 164px;
  height: 164px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  border-radius: 12px;
  margin-bottom: 40px;
  display: flex;
  flex-flow: row;
  align-items: center;
  position: relative;
}

.SciencePopularization_contentItem-img {
  width: 325px;
  height: 164px;
  display: flex;
  justify-content: center;
}

.SciencePopularization_contentItem-right {
  width: calc(100% - 355px);
  height: 100%;
  position: relative;
  margin: 24px 10px 17px 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.SciencePopularization_contentItem-rightTitle {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  line-height: 24px;
}

.SciencePopularization_contentItem-rightDate {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 18px;
  margin-top: 10px;
}

.SciencePopularization_contentItem-rightDesc {
  font-weight: 400;
  font-size: 18px;
  color: #999999;
  line-height: 32px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 限制在一个块元素显示的文本的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* 溢出用省略号表示 */
}

@media only screen and (min-width: 2000px) {
  .SciencePopularization_top {
    left: 10%;
  }

  .SciencePopularization_content {
    margin-left: 10%;
    margin-right: 10%;
  }
}
