.ContactUs {
  /* width: 100%;
  margin-top: 260px; */
  margin-bottom: 240px;
}

.ContactUs_cover {
  background-image: url('../../../../public/image/contactUsBgi.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%;
  width: 100%; */
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.ContactUs_cover-title {
  font-weight: 500;
  font-size: 72px;
  color: #FFFFFF;
  line-height: 100px;
}

.ContactUs_cover-subTitle {
  width: 610px;
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 40px;
  margin-top: 20px;
  text-align: center;
}

.ContactUs_address {
  width: 1157px;
  height: 764px;
  // margin-top: 200px;
  margin: 200px 181px 0 102px;
  // background-color: aliceblue;
  display: flex;
  flex-flow: column;
  // justify-content: center;
  // align-items: center;
  position: relative;
}

.ContactUs_address-bgi1 {
  width: 239px;
  height: 66px;
  position: absolute;
  top: 198px;
  right: 0;
}

.ContactUs_address-bgi2 {
  width: 300px;
  height: 300px;
  background: #3D34D2;
  opacity: 0.04;
  border-radius: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.ContactUs_address-top {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ContactUs_address-title {
  font-weight: 500;
  font-size: 52px;
  color: #000000;
  line-height: 78px;
  position: relative;
  z-index: 10;
}

// .ContactUs_address-title:after {
//   content: '';
//   display: block;
//   width: 210px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 8px;
//   z-index: -1;
// }

.ContactUs_address-desc {
  width: 636px;
  font-weight: 400;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
  margin-top: 12px;
  text-align: center;
}

.ContactUs_address-bottom {
  display: flex;
  flex-flow: row;
  margin-top: 61px;
  gap: 120px;
  z-index: 10;
  margin-left: 138px;
}

.ContactUs_address-bottomItem {
  width: 420px;
  height: 422px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  border-radius: 12px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ContactUs_address-itemImg {
  width: 80px;
  height: 80px;
  margin-top: 55px;
}

.ContactUs_address-itemTitle {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  line-height: 33px;
  margin: 40px 66px 0 66px;
}

.ContactUs_address-itemAddress {
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 28px;
  margin: 16px 72px 0 72px;
  text-align: center;
}

.ContactUs_address-itemEmail {
  font-weight: 500;
  font-size: 20px;
  color: #03004D;
  line-height: 28px;
  margin-top: 16px;
}
