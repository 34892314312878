.App_footer {
  width: 100%;
  height: 416px;
  background: #03004D;
  // margin-top: 242px;
  position: relative;
  overflow: hidden;
}

.App_footer-top {
  width: 100%;
  margin-left: 120px;
  padding-top: 92px;
  // min-height: 304px;
  display: flex;
  flex-flow: row;
  // justify-content: center;
}

.App_footer-topLeft {
  width: 262px;
  height: 89px;
}

.App_footer-topRight {
  margin-left: 153px;
  display: flex;
  flex-flow: row;
  gap: 100px;
}

.App_footer-topRightItem {
  display: flex;
  flex-flow: column;
}

.App_footer-title {
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 32px;
  padding-bottom: 21px;
}

.App_footer-subitem {
  font-weight: 400;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 32px;
  margin: 8px 0;
  text-decoration: none;
}

.App_footer-line {
  width: calc(100% - 270px);
  height: 1px;
  margin: 0 135px;
  margin-top: 23px;
  background: #FFFFFF;
  opacity: 0.12;
}

.App_footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 32px;
  margin-top: 23px;
  flex-flow: column;
}

.App_footer-bgi1 {
  width: 618px;
  height: 618px;
  border-radius: 50%;
  background: #FFFFFF;
  opacity: 0.04;
  position: absolute;
  top: -260px;
  left: -483px;
}

.App_footer-bgi2 {
  width: 518px;
  height: 518px;
  border-radius: 50%;
  background: #FFFFFF;
  opacity: 0.08;
  position: absolute;
  top: -240px;
  left: -420px;
}

.App_footer-bgi3 {
  width: 650px;
  height: 650px;
  border-radius: 50%;
  background: #FFFFFF;
  opacity: 0.04;
  position: absolute;
  bottom: -325px;
  right: -363px;
}

.App_footer-bgi4 {
  width: 550px;
  height: 550px;
  border-radius: 50%;
  background: #FFFFFF;
  opacity: 0.08;
  position: absolute;
  bottom: -275px;
  right: -313px;
}



@media only screen and (min-width: 2000px) {
  .App_footer-top {
    margin-left: 0;
    justify-content: center;
  }
}
