.Fcst {
  margin-bottom: 240px;
}

.Fcst_cover {
  background-image: url('../../../../public/image/fcstBgi.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%;
  width: 100%; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.Fcst_cover-title {
  font-weight: 600;
  font-size: 72px;
  color: #FFFFFF;
  line-height: 100px;
  // margin-top: 50vh;
}

.Fcst_literature {
  // width: calc(100% - 240px);
  height: 833px;
  margin-left: 120px;
  margin-right: 120px;
  margin-top: 200px;
}

.Fcst_title {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  position: relative;
  z-index: 10;
}

// .Fcst_title:after {
//   content: '';
//   display: block;
//   width: 193px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 3px;
//   z-index: -1;
// }

.Fcst_desc {
  font-weight: 400;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
  margin-top: 24px;
  margin-right: 257px;
  width: 743px;
}

.Fcst_btn {
  width: 200px;
  height: 60px;
  // background: #03004D;
  border: 1px solid #03004D;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #03004D;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  text-decoration: none;
}

.Fcst_btn:hover {
  background: #03004D;
  color: #FFFFFF;
}

.Fcst_literature-content {
  width: 100%;
  height: 600px;
  margin-top: 70px;
  // background-color: aliceblue;
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 40px;
  position: relative;
}


.Fcst_literature-left {
  width: 580px;
  height: 600px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.Fcst_literature-left img {
  transition: transform 0.6s ease;
}

.Fcst_literature-left:hover img {
  transform: scale(1.1);
}

.Fcst_literature-leftBgc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.06);
  border-radius: 12px;
  z-index: 1;
}

.Fcst_literature-leftBtn {
  width: 133px;
  height: 29px;
  background: #FFFFFF;
  border-radius: 17px;
  font-weight: 500;
  font-size: 14px;
  color: #03004D;
  line-height: 20px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 2;
}

.Fcst_literature-leftTitle {
  font-weight: 500;
  font-size: 36px;
  color: #FFFFFF;
  line-height: 54px;
  position: absolute;
  // bottom: 96px;
  top: 374px;
  left: 40px;
  right: 40px;
  z-index: 2;
}

.Fcst_literature-leftDate {
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  position: absolute;
  bottom: 53px;
  left: 40px;
  z-index: 2;
}

.Fcst_science {
  // width: calc(100% - 120px);
  // height: 1340px;
  margin-left: 120px;
  margin-top: 120px;
  margin-right: 120px;
  // background-color: aliceblue;
  position: relative;
}

.Fcst_science-contentTop {
  width: calc(100% - 120px);
  height: 500px;
  margin-top: 70px;
  // background-color: aliceblue;
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 40px;
  position: relative;
}

.Fcst_science-contentTopItem {
  width: 373px;
  height: 500px;
  position: relative;
  display: flex;
  align-items: center;
}

.Fcst_science-itemTip {
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  position: absolute;
  top: 309px;
  left: 40px;
}

.Fcst_science-itemTitle {
  font-weight: 500;
  font-size: 36px;
  color: #FFFFFF;
  line-height: 54px;
  position: absolute;
  top: 336px;
  left: 40px;
  right: 40px;
}

.Fcst_science-contentBottom {
  // width: calc(100% - 120px);
  height: 600px;
  margin-top: 40px;
  // background-color: aliceblue;
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 40px;
  position: relative;
}

.Fcst_science-contentBottomItem {
  width: 580px;
  height: 600px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.Fcst_science-contentBottomItem img {
  transition: transform 0.6s ease;
}

.Fcst_science-contentBottomItem:hover img {
  transform: scale(1.1);
}

.Fcst_science-bottomTtemTip {
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  position: absolute;
  top: 343px;
  left: 40px;
  z-index: 2;
}

.Fcst_science-bottomTtemTitle {
  font-weight: 500;
  font-size: 36px;
  color: #FFFFFF;
  line-height: 54px;
  position: absolute;
  top: 400px;
  left: 40px;
  right: 40px;
  z-index: 2;
}

@media only screen and (min-width: 2000px) {
  .Fcst_literature {
    margin-left: 10%;
    margin-right: 10%;
  }

  .Fcst_science {
    margin-left: 10%;
    margin-right: 10%;
  }
}
