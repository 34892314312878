.Strabismus {
  margin-bottom: 240px;
}

.Strabismus_cover {
  background-image: url('../../../../public/image/strabismusBgi.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%;
  width: 100%; */
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  // border-radius: 0px 0px 0px 200px;
}

.Strabismus_cover-title {
  font-weight: 600;
  font-size: 72px;
  color: #FFFFFF;
  line-height: 100px;
}

.Strabismus_content {
  // height: 500px;
  // background-color: aliceblue;
  margin: 200px 120px 0 120px;
}

.Strabismus_content-title {
  font-weight: 500;
  font-size: 48px;
  color: #000000;
  line-height: 68px;
  position: relative;
  z-index: 10;
}

// .Strabismus_content-title:after {
//   content: '';
//   display: block;
//   width: 96px;
//   height: 14px;
//   background: #F0B000;
//   opacity: 0.4;
//   position: absolute;
//   bottom: 6px;
//   left: 190px;
//   z-index: -1;
// }

.Strabismus_content-desc {
  font-weight: 400;
  font-size: 24px;
  color: #999999;
  line-height: 40px;
  margin-top: 24px;
}

.Strabismus_content-video {
  width: 1200px;
  height: 640px;
  margin-top: 70px;
  position: relative;
}

.Strabismus_content-videoBgc {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(61, 52, 210, 0.06);
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.04);
  border-radius: 12px;
}

.Strabismus_content-img2 {
  width: 1200px;
  height: 212px;
  margin-top: 70px;
}

.Strabismus_content-groupImgs {
  display: flex;
  flex-flow: row;
  gap: 40px;
  margin-top: 70px;
  justify-content: center;
}

.Strabismus_content-groupItem {
  width: 580px;
  height: 486px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px 0px rgba(3, 0, 77, 0.06);
  border-radius: 12px;
}

.Strabismus_content-groupItemTitle {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  line-height: 38px;
  margin-top: 30px;
  margin-left: 20px;
}

.Strabismus_content-img3 {
  width: 580px;
  height: 306px;
  margin-top: 22px;
}

@media only screen and (min-width: 2000px) {
  .Strabismus_content {
    margin-left: 10%;
    margin-right: 10%;
  }
}




/* demo */
.Demo_head {
  width: 100%;
  height: 500px;
  top: 20px;
  background-color: #dad9d9;
  position: relative;
  animation: resize 2s forwards;
}

@keyframes resize {
  0% {
    width: 100%;
    margin: 0 auto;
  }

  100% {
    width: 90%;
    margin: 0 auto;
  }
}

.head-wrapper {
  text-align: center;
  top: calc(100px + 7.5vh);
  position: sticky;
  /* sticky如果去掉，top将不生效 */
  height: 100px;
}

.wrapper-title {
  font-size: 24px;
  animation: titleUp 3s forwards 1s;
  opacity: 0;
}

@keyframes titleUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
    /* 初始状态不可见 */
  }

  100% {
    transform: translateY(0);
    opacity: 0.99;
  }
}

.wrapper-subtitle {
  font-size: 20px;
  color: #86868b;
  margin-top: 20px;
  animation: subtitleUp 3.5s forwards 1s;
  opacity: 0;
}

@keyframes subtitleUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
    /* 初始状态不可见 */
  }

  100% {
    transform: translateY(0);
    opacity: 0.99;
  }
}

.hero-overflow-container {
  overflow: hidden;
  position: absolute;
  height: calc(100% - 20px);
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 5;
  margin-top: 20px;
}

.container {
  width: 100%;
  height: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 60px;
}

.scroll-bar {
  width: 50%;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(90deg, #D8D8D8, #555558);
  animation: scrollAnimation 3s forwards;
}

@keyframes scrollAnimation {
  from {
    width: 0;
    border-radius: 5px;
  }

  to {
    width: 100%;
    border-radius: 5px;
    width: 50%;
  }
}
